/* css styles for the d3 output */

.node {
  font: 300 11px "Roboto", "Helvetica", "Arial", sans-serif;
  fill: #bbb;
}

.node:hover {
  fill: #000;
}

.link {
  stroke: #3f51b5;
  stroke-opacity: 0.4;
  fill: none;
  pointer-events: none;
}

.node:hover,
.node--source,
.node--target {
  font-weight: 700;
}

.node--source {
  fill: #357a38;
}

.node--target {
  fill: #f50057;
}

.link--source,
.link--target {
  stroke-opacity: 1;
  stroke-width: 2px;
}

.link--source {
  stroke: #f50057;
}

.link--target {
  stroke: #357a38;
}

text.node {
  cursor: default;
}

text.node[id] {
  font-weight: 900;
  font-size: 11.5px;
  text-decoration: underline;
}

/* styles for the tabs and the pubtitle within the CitationViz-component */
.tabTitle {
  padding: 10px;
}

.pubTitle {
  align-self: flex-start;
}

/* set the red dot for the active-filter-state */
.MuiButtonBase-root#filtered .MuiTab-wrapper::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #f50057;
  border-radius: 50%;
}
