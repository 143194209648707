/* standard css styles for the whole result view layout */

*,
:after,
:before {
  box-sizing: border-box;
  /* clear: both; */
}

.App {
  text-align: left;
  display: flex;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  flex-flow: row wrap;
}

.App > div {
  padding: 30px;
  overflow: hidden;
}
/* publist and metadata got only 40% viewport-width */
.App .left {
  width: 40%;
}
.App .left > * {
  margin-bottom: 20px;
}
/* the visualization got 60% viewport-width */
.App .CitationViz {
  width: auto;
  max-width: 60%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

/* media breakpoint for mobile view */
@media screen and (max-width: 1000px) {
  .App .CitationViz,
  .App .left {
    width: 100%;
    max-width: 100%;
    padding: 10px 20px 0;
  }
  .App .left > * {
    float: left;
    max-width: 49%;
    width: 48%;
    margin: 1%;
    max-height: unset;
  }
}
.App .CitationViz .pubTitle {
  margin-bottom: 16px;
}

/* styles for the publist */
.App .pubList ul li {
  padding-bottom: 10px;
}
.App .pubList ul li:hover {
  list-style-type: circle;
}

.pubList ul {
  max-height: 40vh;
  overflow-y: scroll;
}

li:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

li .title {
  font-weight: 600;
}

li.scigraph .title:hover,
li.scigraph .auth:hover {
  text-decoration: underline;
  /* text-decoration-color: #3f51b5; */
  cursor: pointer;
}

li.crossref .title:hover,
li.crossref .auth:hover {
  text-decoration: underline;
  /* text-decoration-color: #f50057; */
  cursor: pointer;
}

/* li:not(:only-child).active .title, li .auth.active { */
li.active .title,
li .auth.active {
  /* font-weight: 800; */
  color: #f50057;
  /* text-decoration: underline; */
}

/* styles for the startpage / search view */

.searchView {
  display: flex;
  flex-flow: column wrap;
  height: 100vh;
  padding: 20px;
}

.searchView > div {
  padding: 10px;
  overflow: hidden;
}

.searchView .form > * {
  margin: 5px 0px 5px 10px;
}

/* styles for the loading page and its animation */

.loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  padding: 20px;
}

.loader .loadInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
}

.loader .lds-grid {
  margin: 15px;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader .lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #f50057;
  animation: lds-grid 1.2s linear infinite;
}
.loader .lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.loader .lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.loader .lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.loader .lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.loader .lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.loader .lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.loader .lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.loader .lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.loader .lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
